// SpinnerContext.js
import React, {createContext, useState, useContext} from 'react';

const SpinnerContext = createContext();

//initial Default value
export const SpinnerProvider = ({children}) => {
  const [spinning, setSpinning] = useState(false);

  const toggleSpinner = value => {
    setSpinning(value);
  };

  return <SpinnerContext.Provider value={{spinning, toggleSpinner}}>{children}</SpinnerContext.Provider>;
};

export const useSpinnerContext = () => useContext(SpinnerContext);
export default SpinnerProvider;
