import 'global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'components/kit/vendors/antd/mixins.less';

import {SpinnerProvider} from 'context/spinnerContext';
import CalenderProvider from 'context/calenderContext';
import TeamProvider from 'context/teamIDContext';
import DayCalenderProvider from 'context/dayCalenderContext';
import WeekOrDaySelectProvider from 'context/weekOrDaySelectContext';
import MenuProvider from 'context/menuContext';
import GuideProvider from 'context/guideContext';
import {OnboardStepsContextProvider} from 'context/onboardStepsContext';

import Router from 'router';

// Set console log to empty function to avoid consoling in production.
if (process.env.REACT_APP_NODE_ENV === 'production') console.log = function () {};

const App = () => {
  return (
    <SpinnerProvider>
      <GuideProvider>
        <CalenderProvider>
          <TeamProvider>
            <MenuProvider>
              <OnboardStepsContextProvider>
                <DayCalenderProvider>
                  <WeekOrDaySelectProvider>
                    <Router />
                  </WeekOrDaySelectProvider>
                </DayCalenderProvider>
              </OnboardStepsContextProvider>
            </MenuProvider>
          </TeamProvider>
        </CalenderProvider>
      </GuideProvider>
    </SpinnerProvider>
  );
};

export default App;
