import React, {createContext, useContext, useState, useEffect, useMemo} from 'react';

const LocalStorageContext = createContext();
export const OnboardStepsContextProvider = ({children}) => {
  const [data, setData] = useState({index: 0});

  useEffect(() => {
    const storedData = sessionStorage.getItem('myData');
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, []);

  const setDataInLocalStorage = newData => {
    sessionStorage.setItem('myData', JSON.stringify(newData));
    setData(newData);
  };
  const value = useMemo(() => ({getLocalStorageData: data, setDataInLocalStorage}), [data]);
  return <LocalStorageContext.Provider value={value}>{children}</LocalStorageContext.Provider>;
};

export const useLocalStorage = () => useContext(LocalStorageContext);
