import React from 'react';
import {DatePicker, Space} from 'antd';
import moment from 'moment';
import {useDayCalenderContext} from 'context/dayCalenderContext';
import {useCalenderContext} from 'context/calenderContext';
// import { useMenuContext } from 'context/menuContext';

const DayCalender = () => {
  const {dayCalenderDate, setDayCalenderDate} = useDayCalenderContext();
  const {setOnChangeWeekDate, setCalenderDate} = useCalenderContext();
  // const { menu } = useMenuContext();

  const dateFormat = 'DD/MM/YYYY';

  const onChangeDate = date => {
    setOnChangeWeekDate(true);
    const dayDate = moment(date).format('YYYY-MM-DD');
    const startOfWeek = moment(date).startOf('isoweek').format('YYYY-MM-DD');
    const endOfWeek = moment(date).endOf('isoweek').format('YYYY-MM-DD');
    setDayCalenderDate({day: dayDate});
    setCalenderDate({startOfWeek, endOfWeek});
  };
  return (
    <div style={{marginTop: '-14px', marginBottom: '-14px'}}>
      <Space style={{width: '140px'}} direction="vertical" size={12}>
        <DatePicker
          allowClear={false}
          onChange={onChangeDate}
          showToday={false}
          disabledDate={current => current.isAfter(moment())}
          className="w-100"
          value={moment(`${moment(dayCalenderDate.day).format(dateFormat)}`, 'DD/MM/YYYY')}
          format={dateFormat}
          // disabled={menu === 'Dead KMs'}
        />
      </Space>
    </div>
  );
};
export default DayCalender;
