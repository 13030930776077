const multiTabLink = ({onClick, onContextMenu, url, target = '_top', title, className, style, type}) => {
  switch (type) {
    case 'button':
      return (
        <a
          href={url}
          target="_top"
          onClick={onClick}
          onContextMenu={onContextMenu}
          className={`text-[#000000] py-1 px-2 border-solid border-1 border-[#cacccf] rounded-sm hover:border-[#40a9ff] ${className}`}
          style={style}
        >
          {title || ''}
        </a>
      );
    default:
      return (
        <a
          href={url}
          target={target}
          onClick={onClick}
          onContextMenu={onContextMenu}
          className={className}
          style={style}
        >
          {title || ''}
        </a>
      );
  }
};

export default multiTabLink;
