import {Layout} from 'antd';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import WebHeader from 'visitorFormImages/WebHeader.png';
import MobileHeaderImg from 'visitorFormImages/MobileHeaderImg.png';
import MobileHeader from 'visitorFormImages/MobileHeader.png';

const {Content} = Layout;

const AuthLayout = ({children}) => {
  const {pathname} = useLocation();
  const CONDITION = ['/visitors', '/verifyOTP', '/thankYouPage'].includes(pathname);

  return (
    <div>
      {!CONDITION ? (
        <Layout style={{fontFamily: 'montserrat', overflowX: 'hidden'}}>
          <Content>{children}</Content>
        </Layout>
      ) : (
        // <Layout>
        <div className="bg-[#ffffff]">
          <img src={WebHeader} className="d-none d-md-block" width="100%" style={{height: '220px'}} alt="header" />
          {pathname === '/verifyOTP' ? (
            <img
              src={MobileHeaderImg}
              className="d-block d-md-none"
              width="100%"
              style={{height: '380px'}}
              alt="header"
            />
          ) : (
            <img src={MobileHeader} className="d-block d-md-none" width="100%" style={{height: '180px'}} alt="header" />
          )}
          <Content>{children}</Content>
        </div>
        // </Layout>
      )}
    </div>
  );
};

export default AuthLayout;
