/* eslint-disable react/jsx-no-constructed-context-values */
import {createContext, useState, useContext} from 'react';

// initial default value
const TeamContext = createContext({
  teamIDValue: 1,
  partnerIDValue: 1,
  managerIDValue: 1,
  cityIDValue: 1,
  locIDValue: 1,
  carNumber: {carNumberValue: '', carCode: ''},
  driverID: {driverIDValue: '', carCode: ''},
  driverETM: '',
  recovery: 0,
  master: 0,
  revenue: 0,
  dashboardType: '',
  selectedDashboard: null,
  cityValue: 1,
});

const TeamProvider = ({children}) => {
  const carType = ['active', 'nd', 'repair', 'insurance', 'breakdown', 'b2b', 'Rental', 'Fitness_parking', 'parking'];

  const [teamID, setTeamID] = useState({
    teamIDValue: 1,
  });
  const [partnerID, setPartnerID] = useState({
    partnerIDValue: 1,
  });
  const [managerID, setManagerID] = useState({
    managerIDValue: 1,
  });
  const [cityID, setCityID] = useState({
    cityIDValue: 1,
  });
  const [locID, setLocID] = useState({
    locIDValue: 1,
  });
  const [carNumber, setCarNumber] = useState({
    carNumberValue: '',
    carCode: '',
  });
  const [driverID, setDriverID] = useState({
    driverIDValue: '',
    driverCode: '',
  });
  const [driverETM, setDriverETM] = useState('');
  const [recovery, setRecovery] = useState(0);
  const [master, setMaster] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [dashboardType, setDashboardType] = useState('');
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [cityDropdown, setCityDropdown] = useState({cityValue: 1});
  return (
    <TeamContext.Provider
      value={{
        teamID,
        setTeamID,
        partnerID,
        setPartnerID,
        managerID,
        setManagerID,
        cityID,
        setCityID,
        locID,
        setLocID,
        carType,
        carNumber,
        setCarNumber,
        driverID,
        setDriverID,
        driverETM,
        setDriverETM,
        recovery,
        setRecovery,
        master,
        setMaster,
        revenue,
        setRevenue,
        dashboardType,
        setDashboardType,
        selectedDashboard,
        setSelectedDashboard,
        cityDropdown,
        setCityDropdown,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeamContext = () => useContext(TeamContext);
export default TeamProvider;
