/* eslint-disable react/jsx-no-constructed-context-values */
import {createContext, useState, useContext} from 'react';

// initial default value
const MenuContext = createContext({
  menu: '',
  pageObject: {
    label: 'Dashboard',
    key: '/home/dashboard',
    icon: '',
    uri: 'dashboard',
    calendar_type: '"THIS_WEEK"',
  },
  previousRoute: {
    path: '',
    currentPage: 1,
    pageSize: 10,
    filters: {},
  },
  routeHistory: [],
});

const MenuProvider = ({children}) => {
  const [menu, setMenu] = useState('');
  const [routeHistory, setRouteHistory] = useState([]);
  const [previousRoute, setPreviousRoute] = useState({});
  const [pageObject, setPageObject] = useState({
    label: 'Dashboard',
    key: '/home/dashboard',
    icon: '',
    uri: 'dashboard',
    calendar_type: '"THIS_WEEK"',
  });

  return (
    <MenuContext.Provider
      value={{
        menu,
        setMenu,
        routeHistory,
        setRouteHistory,
        previousRoute,
        setPreviousRoute,
        pageObject,
        setPageObject,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);
export default MenuProvider;
