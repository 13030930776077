import {Spin} from 'antd';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {useLocation, Redirect, useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import moment from 'moment';

// layout imports
import PublicLayout from './public';
import AuthLayout from './auth';
import MainLayout from './main';

import {Dashboard} from 'utility/dashboardEnum';

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
};

const getLayout = pathname => {
  const CONDITION = ['/visitors', '/verifyOTP', '/thankYouPage'].includes(pathname);

  if (pathname === '/') return 'public';
  // if (/^\/auth(?=\/|$)/i.test(pathname)) return 'auth';
  if ('/auth/sign-in' === pathname || CONDITION) return 'auth';
  return 'main';
};

function handleDefaultNavigation(history, dashboard) {
  switch (dashboard) {
    case Dashboard.AUDITDASHBOARD:
      history.push('/home/audit/checkIn/checkIn');
      break;
    case Dashboard.RECOVERYDASHBOARD:
      history.push('/home/recoveryDash');
      break;
    case Dashboard.ONBOARDDRIVER:
      history.push('/home/onboardDriver');
      break;
    case Dashboard.JARVIS:
      history.push('/home/paymentReport');
      break;
    default:
      history.push('/home/dashboard');
  }
}

const SelectedLayout = ({isLoading, isUserAuthorized, dashboard, children}) => {
  const {pathname} = useLocation();
  const history = useHistory();

  const layout = getLayout(pathname);
  const isAuthLayout = layout === 'auth';
  const Container = Layouts[layout];

  useEffect(() => {
    // General default navigation after login acc to dashboard
    if (!isLoading && isAuthLayout && isUserAuthorized) {
      handleDefaultNavigation(history, dashboard);
    }
  }, [isAuthLayout, isUserAuthorized, dashboard, isLoading]);

  // show loader when user in check authorization process
  if (isLoading && !isAuthLayout && !isUserAuthorized) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spin spinning />
      </div>
    );
  }

  // // redirect to login page if current is not login page and user not authorized

  if (!isAuthLayout && !isUserAuthorized) {
    return <Redirect to="/auth/sign-in" />;
  }

  // in other case render previously set layout
  return <Container path={pathname}>{children}</Container>;
};

const Layout = ({dashboard, isLoading, isUserAuthorized, children}) => {
  useEffect(() => {
    moment.updateLocale('en', {
      week: {
        dow: 1, /// Date offset
      },
    });
  }, []);

  return (
    <>
      <Helmet titleTemplate="Everest Fleet | %s" />
      <SelectedLayout isLoading={isLoading} isUserAuthorized={isUserAuthorized} dashboard={dashboard}>
        {children}
      </SelectedLayout>
    </>
  );
};

const mapStateToProps = ({userReducer}) => ({
  isLoading: userReducer.loading,
  isUserAuthorized: userReducer.authorized,
  dashboard: userReducer.dashboard,
});

export default connect(mapStateToProps, null)(Layout);
