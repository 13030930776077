import {createContext, useState, useContext, useEffect, useMemo} from 'react';
import moment from 'moment';
import {useMenuContext} from './menuContext';

const DayCalenderContext = createContext({
  day: moment().subtract(1, 'days').format('YYYY-MM-DD'),
});

const DayCalenderProvider = ({children}) => {
  const {pageObject} = useMenuContext();
  const [dayCalenderDate, setDayCalenderDate] = useState({
    day: moment().subtract(1, 'days').format('YYYY-MM-DD'),
  });
  useEffect(() => {
    let dateTemp = null;
    if (['TODAY'].includes(pageObject?.calendar_type)) {
      dateTemp = {
        day: moment().format('YYYY-MM-DD'),
      };
    } else {
      dateTemp = {
        day: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      };
    }
    setDayCalenderDate(dateTemp);
  }, [pageObject?.uri]);

  const value = useMemo(
    () => ({
      dayCalenderDate,
      setDayCalenderDate,
    }),
    [dayCalenderDate]
  );

  return <DayCalenderContext.Provider value={value}>{children}</DayCalenderContext.Provider>;
};

export const useDayCalenderContext = () => useContext(DayCalenderContext);
export default DayCalenderProvider;
