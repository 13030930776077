import {notification} from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';

import {store} from 'redux/store';
import actions from 'redux/user/actionTypes';
import {Dashboard, INVALID_TOKEN_ERROR, LogoutActions} from 'utility/dashboardEnum';
import client from './client';
import {onFetchDriverManagerDetails} from './axios';

export const login = async payload => {
  const {formData} = payload;

  try {
    const {
      data: {token, name},
    } = await client.post('/jarvis_api/api/login/', formData);
    Cookies.set('token', token);
    Cookies.set('name', name);
    Cookies.set('username', formData.username);
    store.dispatch({
      type: actions.SET_STATE,
      payload: {
        name,
        token,
        username: formData.username,
      },
    });

    const permissions = await client.get(`${process.env.REACT_APP_FLEET_SERVER_URL}${'/user-module/permissions/'}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });

    if (!Object.keys(permissions).length) {
      throw new Error("User doesn't have any permission");
    }

    localStorage.setItem('permissions', JSON.stringify(permissions.data));
    store.dispatch({
      type: actions.SET_STATE,
      payload: {
        permissions: permissions.data,
      },
    });

    /* ///////////// TO GET USER DEFAULT CITY ////////////// */

    const agentCity = await client.get(`${process.env.REACT_APP_JARVIS_SERVER_URL}${'/fleet_apis/agentCity'}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });
    const cityID = agentCity.data.CityId !== '' || agentCity.data.CityId !== undefined ? agentCity.data.CityId : 1;
    store.dispatch({type: actions.SAVE_DRIVER_CITY, payload: cityID});
    store.dispatch({type: actions.DRIVER_DEFAULT_CITY, payload: cityID});

    return true;
  } catch (error) {
    console.log('Error', error);
    if (error?.message) {
      notification.error({
        description: error?.message,
      });
    }
    return false;
  }
};

function removeData() {
  Cookies.remove('token');
  Cookies.remove('name');
  Cookies.remove('team');
  Cookies.remove('teamName');
  Cookies.remove('manager');
  Cookies.remove('managerName');
  Cookies.remove('city');
  Cookies.remove('cityName');
  Cookies.remove('loc');
  Cookies.remove('location');
  Cookies.remove('partner');
  Cookies.remove('revenue');
  Cookies.remove('businessVerticalId');
  Cookies.remove('paymentModelId');
  Cookies.remove('teamInfo');
  Cookies.remove('username');
  localStorage.removeItem('permissions');
  localStorage.clear();
  sessionStorage.clear();
}

// load current account if token is verified and dispatch data
export const currentAccountLoad = async () => {
  try {
    const {
      userReducer: {token, permissions, dashboard, username},
    } = store.getState();

    if (!token) return false;

    if (!Object.keys(permissions).length) {
      throw new Error("User doesn't have any permission");
    }

    // To check whether selectedDash is even present in permissions
    const CONDITION = Object.keys(permissions).includes(dashboard);

    let selectedDash;
    if (CONDITION) {
      selectedDash = dashboard;
    } else {
      // eslint-disable-next-line prefer-destructuring
      selectedDash = Object.keys(permissions)[0];
      store.dispatch({
        type: actions.SET_STATE,
        payload: {
          dashboard: Object.keys(permissions)[0],
        },
      });
      Cookies.set('dashboard', Object.keys(permissions)?.[0]);
    }

    if (![Dashboard.MANAGEMENTDASHBOARD, Dashboard.ONBOARDDRIVER, Dashboard.JARVIS].includes(selectedDash)) {
      const dashUri = selectedDash.split(' ')[0].toLowerCase();
      const managerResponse = await onFetchDriverManagerDetails({
        user: username,
        dashboard: dashUri,
      });
      if (managerResponse.data.error) {
        notification.error({
          description: managerResponse.data.error,
        });
      }
      const {
        team,
        team_name,
        manager_id,
        manager_name,
        city_id,
        city_name,
        loc_id,
        location,
        partner_id,
        revenue_type,
        business_vertical_id,
        payment_model_id,
      } = managerResponse.data;

      let teamResponse;
      if (team) {
        teamResponse = await client.get(`/fleet/api/teamApi/${team}/`);
      }

      Cookies.set('team', team);
      Cookies.set('teamName', team_name);
      Cookies.set('manager', manager_id);
      Cookies.set('managerName', manager_name);
      Cookies.set('city', city_id);
      Cookies.set('cityName', city_name);
      Cookies.set('loc', loc_id);
      Cookies.set('location', location);
      Cookies.set('partner', partner_id);
      Cookies.set('revenue', revenue_type);
      Cookies.set('businessVerticalId', business_vertical_id);
      Cookies.set('paymentModelId', payment_model_id);
      Cookies.set('teamInfo', teamResponse?.data || {});

      store.dispatch({
        type: actions.SET_STATE,
        payload: {
          team,
          teamName: team_name,
          manager: manager_id,
          managerName: manager_name,
          city: city_id,
          cityName: city_name,
          loc: loc_id,
          location,
          partner: partner_id,
          revenue: revenue_type,
          businessVerticalId: business_vertical_id,
          paymentModelId: payment_model_id,
          teamInfo: teamResponse?.data || {},
        },
      });

      /* //////////////////////////////////////////////////////// */
    } else {
      Cookies.set('teamInfo', {});
      store.dispatch({
        type: actions.SET_STATE,
        payload: {
          teamInfo: {},
        },
      });
    }

    return true;
  } catch (error) {
    console.log('Error', error);
    if (error.response?.data?.detail === INVALID_TOKEN_ERROR) {
      return false;
    }
    notification.error({
      description: error?.message,
    });
    return true;
  }
};

// logout and remove all the cookies
export const logout = async action => {
  const {SIGNOUT, SESSION_EXPIRED} = LogoutActions;
  try {
    const token = Cookies.get('token');

    const response = await axios({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}/api/logout/`,
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (action === SIGNOUT) {
      notification.success({
        message: response?.data?.detail,
      });
    } else if (action === SESSION_EXPIRED) {
      notification.error({
        message: 'Session expired, please login again.',
      });
    }
    return true;
  } catch (error) {
    if (action === SIGNOUT) {
      notification.success({
        message: 'Successfully logged out.',
      });
    } else if (action === SESSION_EXPIRED) {
      notification.error({
        message: 'Session expired, please login again.',
      });
    }
    return true;
  } finally {
    removeData();
  }
};
