import axios from 'axios';
import Cookies from 'js-cookie';
import {store} from 'redux/store';
import {userLogout} from 'redux/user/actions';
import {INVALID_TOKEN_ERROR, LogoutActions} from 'utility/dashboardEnum';

const commonClient = axios.create();

commonClient.interceptors.request.use(
  config => {
    const token = Cookies.get('token');
    const auth = token ? `Token ${token}` : '';

    // Ensure headers object is defined
    config.headers = config.headers || {};

    config.headers.Authorization = auth;
    config.headers.source = 'hawkeye-driver-onboarding';

    // Set Content-Type based on the request data
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  error => Promise.reject(error)
);

commonClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.data?.detail === INVALID_TOKEN_ERROR) {
      store.dispatch(userLogout(LogoutActions.SESSION_EXPIRED));
    }
    return Promise.reject(error);
  }
);

export default commonClient;
