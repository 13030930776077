import {Layout, Spin} from 'antd';
import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import moment from 'moment';

import {store} from 'redux/store';

import useWindowSize from 'hooks/useResize';
import {useTeamContext} from 'context/teamIDContext';
import {useSpinnerContext} from 'context/spinnerContext';

import DashboardHeader from 'components/layouts/dashboardHeader';
import AppHeader from 'components/layouts/AppHeader';
import Sidebar from 'components/layouts/sidebar';

const {Header, Sider, Content} = Layout;

const getStyle = pathname => {
  if (pathname === '/home/dashboard') {
    return {
      padding: 0,
      height: 'auto',
      boxShadow:
        '0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12)',
    };
  }
  return {
    padding: 0,
    height: '80px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12)',
  };
};

const MainLayout = ({children}) => {
  const {
    userReducer: {name, manager, cityName, location, teamName, username, dashboard},
  } = store.getState();
  const {pathname} = useLocation();
  const windowSize = useWindowSize();

  const {setSelectedDashboard} = useTeamContext();
  const {spinning} = useSpinnerContext();

  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);

  // Appcues: Identify users
  useEffect(() => {
    window.Appcues.identify(username?.toString(), {
      name,
      manager: manager?.[0],
      teamName: teamName?.[0],
      cityName: cityName?.[0],
      location: location?.[0],
      dashboard,
      page: 'Login',
      created_at: moment().format('YYYY-MM-DD, h:mm:ss a'),
    });
  }, []);

  useEffect(() => {
    setSelectedDashboard(dashboard);
  }, [dashboard]);

  const hideLayout = [
    '/home/driverHisaabData',
    '/home/deadKMsReportData',
    '/home/driverLeasHisaab/:id',
    '/home/hisaabSummaryTable/:revenueType/:startOfWeek/:endOfWeek/:loaderName/:tableTitle',
  ].includes(pathname);

  const onBreakpoint = broken => {
    if (broken) {
      setCollapsedWidth(0);
    } else {
      setCollapsedWidth(80);
    }
  };

  let laptop;
  if (windowSize.width < 992) {
    laptop = true;
  } else {
    laptop = false;
  }

  return (
    <Spin spinning={spinning}>
      <Layout style={{fontFamily: 'montserrat'}}>
        {!laptop && !hideLayout && (
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(prevState => !prevState)}
            width={250}
            style={{
              height: '94vh',
              position: 'sticky',
              overflow: 'auto',
              left: 0,
              top: 0,
              bottom: 0,
            }}
            className="shadow-sm"
            breakpoint="lg"
            onBreakpoint={broken => onBreakpoint(broken)}
            collapsedWidth={collapsedWidth}
          >
            <Sidebar />
          </Sider>
        )}
        <Layout style={{minHeight: '100vh'}}>
          {!hideLayout && (
            <Header style={getStyle(pathname)}>
              {pathname === '/home/dashboard' ? <DashboardHeader /> : <AppHeader />}
            </Header>
          )}
          <Content className="mt-1.5">{children}</Content>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default MainLayout;
