export const Dashboard = Object.freeze({
  JARVIS: 'Jarvis',
  HAWKEYE: 'Hawkeye Dashboard',
  ONBOARDDRIVER: 'Onboard Driver',
  MANAGEMENTDASHBOARD: 'Management Dashboard',
  AUDITDASHBOARD: 'Audit Dashboard',
  LEASINGDASHBOARD: 'Leasing Dashboard',
  RECOVERYDASHBOARD: 'Recovery Dashboard',
  EIPDASHBOARD: 'EIP Dashboard',
});

export const LogoutActions = Object.freeze({
  SIGNOUT: 'signout',
  SESSION_EXPIRED: 'sessionExpired',
  PASSWORD: 'password',
});

export const INVALID_TOKEN_ERROR = 'Invalid token.';
