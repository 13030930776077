/* eslint-disable react/no-unstable-nested-components */
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import {Input, Modal, notification} from 'antd';
import {useEffect, useState} from 'react';

import {onPostUpdatePasswordApi} from 'services/axios';
import {LogoutActions} from 'utility/dashboardEnum';

const responsiveFlex = 'flex flex-col gap-2 w-2/3';
const labelClassName = 'justify-self-end font-semibold text-neutral-600 text-sm whitespace-nowrap m-0';

const isPasswordValidate = password => {
  const re = {
    length: /(?=.{8,40}$)/, // char length between 8 to 40
    specialChar: /[ -/:-@[-`{-~]/, // at least one symbol
    digit: /(?=.*[0-9])/, // at least one number
  };
  let errors = [];
  if (!re.length.test(password)) {
    errors = [...errors, 'length should be at least 8 character'];
  }
  if (!re.specialChar.test(password)) {
    errors = [...errors, 'should contain at least one symbol'];
  }
  if (!re.digit.test(password)) {
    errors = [...errors, 'should contain at least one number'];
  }

  if (!errors.length) return false;

  return errors;
};

function UpdatePassModal({isModalOpen, setModalOpen, logout}) {
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState({
    old_password: false,
    new_password: false,
    confirm_new_password: false,
  });
  const [formData, setFormData] = useState({
    old_password: '',
    new_password: '',
    confirm_new_password: '',
  });

  const [errorStrings, setErrorStrings] = useState({});

  useEffect(() => {
    if (formData.new_password && !errorStrings.new_password) {
      const errors = isPasswordValidate(formData.new_password);
      if (errors) {
        setErrorStrings(prev => ({
          ...prev,
          new_password: errors,
        }));
      }
    }
  }, [formData.new_password]);

  useEffect(() => {
    if (formData.new_password !== formData.confirm_new_password) {
      setErrorStrings(prev => ({
        ...prev,
        confirm_new_password: ['confirm password not matching new password!'],
      }));
    } else {
      // eslint-disable-next-line no-unused-vars
      const {confirm_new_password, ...remainingProperties} = errorStrings;
      setErrorStrings({
        ...remainingProperties,
      });
    }
  }, [formData.new_password, formData.confirm_new_password]);

  useEffect(() => {
    if (formData.new_password && formData.new_password === formData.old_password) {
      setErrorStrings(prev => ({
        ...prev,
        new_password: ['new password should not be same as old password!'],
      }));
    }
  }, [formData.new_password, formData.old_password]);

  const handleUpdate = () => {
    if (Object.keys(errorStrings).length) return;

    setErrorStrings({});

    if (!formData.old_password || !formData.new_password || !formData.confirm_new_password) {
      setErrorStrings(prev => ({
        ...prev,
        ...(!formData.old_password && {
          old_password: ['This field is required'],
        }),
        ...(!formData.new_password && {
          new_password: ['This field is required'],
        }),
        ...(!formData.confirm_new_password && {
          confirm_new_password: ['This field is required'],
        }),
      }));
      return;
    }

    setLoading(true);
    onPostUpdatePasswordApi(formData)
      .then(() => {
        notification.success({
          message: 'Password updated successfully',
        });
        setFormData({
          old_password: '',
          new_password: '',
          confirm_new_password: '',
        });
        setModalOpen(false);
        logout(LogoutActions.PASSWORD);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
          const err = error.response.data;
          notification.error({
            message: err.message,
          });
          if (typeof err.error_message !== 'string' && err.error_message.constructor.name === 'Object') {
            setErrorStrings(err.error_message);
          }
        } else if (error.request) {
          console.log('Request Error:', error.request);
        } else {
          console.log('Error', error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = e => {
    const {name, value} = e.target;
    if (errorStrings[name]) {
      // eslint-disable-next-line no-unused-vars
      const {[name]: removedProperty, ...remainingProperties} = errorStrings;
      setErrorStrings({
        ...remainingProperties,
      });
    }

    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleErrorString = name => {
    if (errorStrings?.[name]) {
      return (
        <div className="text-red-500 font-semibold text-xs">
          {errorStrings[name].map(error => (
            <p key={error} className="p-0 m-0">
              {error}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      title="Update password"
      open={isModalOpen}
      onOk={handleUpdate}
      onCancel={() => setModalOpen(false)}
      okText="Submit"
      confirmLoading={loading}
      destroyOnClose
      okButtonProps={{
        disabled: Object.keys(errorStrings).length,
      }}
      width={400}
    >
      <div className="flex flex-col gap-2 items-center">
        {/* Old password */}
        <div className={responsiveFlex}>
          <p className={labelClassName}>
            Old password: <span className="text-red-600">*</span>
          </p>
          <div className="col-span-2">
            <Input.Password
              type="password"
              autoComplete="new-password"
              name="old_password"
              value={formData.old_password}
              onChange={handleInputChange}
              status={errorStrings?.old_password ? 'error' : ''}
              allowClear
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              visibilityToggle={{
                visible: passwordVisible.old_password,
                onVisibleChange: setPasswordVisible,
              }}
            />
            {handleErrorString('old_password')}
          </div>
        </div>
        {/* New password */}
        <div className={responsiveFlex}>
          <p className={labelClassName}>
            New password: <span className="text-red-600">*</span>
          </p>
          <div className="col-span-2">
            <Input.Password
              type="password"
              autoComplete="new-password"
              name="new_password"
              value={formData.new_password}
              onChange={handleInputChange}
              status={errorStrings?.new_password ? 'error' : ''}
              allowClear
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              visibilityToggle={{
                visible: passwordVisible.new_password,
                onVisibleChange: setPasswordVisible,
              }}
            />
            {handleErrorString('new_password')}
          </div>
        </div>
        {/* Confirm new password */}
        <div className={responsiveFlex}>
          <p className={labelClassName}>
            Confirm new password: <span className="text-red-600">*</span>
          </p>
          <div className="col-span-2">
            <Input.Password
              type="password"
              autoComplete="new-password"
              name="confirm_new_password"
              value={formData.confirm_new_password}
              onChange={handleInputChange}
              status={errorStrings?.confirm_new_password ? 'error' : ''}
              // disabled={!formData.new_password || errorStrings?.new_password}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              visibilityToggle={{
                visible: passwordVisible.confirm_new_password,
                onVisibleChange: setPasswordVisible,
              }}
              allowClear
            />
            {handleErrorString('confirm_new_password')}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UpdatePassModal;
