import {createContext, useState, useContext, useMemo} from 'react';

// initial default value
const GuideContext = createContext();

const GuideProvider = ({children}) => {
  const [onBoarding, setOnboarding] = useState({});

  const value = useMemo(() => ({onBoarding, setOnboarding}), [onBoarding]);

  return <GuideContext.Provider value={value}>{children}</GuideContext.Provider>;
};

export const useGuideContext = () => useContext(GuideContext);
export default GuideProvider;
